var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "website" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("pa-header"),
      _vm._v(" "),
      _c("router-view"),
      _vm._v(" "),
      _c("pa-footer"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          position: "absolute",
          margin: "0",
          width: "0",
          height: "0",
          overflow: "hidden",
          top: "-20px",
        },
      },
      [
        _c("img", {
          staticStyle: { width: "320px", height: "320px" },
          attrs: { src: require("./img/share-icon.png") },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }